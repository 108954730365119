import { useAuth } from '@context/AuthContext';
import { useI18n } from '@core/hooks/useI18n';
import { LanguageType, PostBodyLoginInterface } from '@interface/UserInterface';
import { localStorageService } from 'core/utils/localStorage';
import { useFormik } from 'formik';
import Image from 'next/image';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseButton,
  BaseMenuDropdown,
  BaseText,
  BaseTextInput,
  ColorBlue,
  ColorLight
} from 'uangcermat-web-toolkit-v2';
import * as Yup from 'yup';

const isDev = process.env.NODE_ENV === 'development';

const mockLogin = {
  email: 'test+manager1-newpc@gajicermat.com',
  password: 'gajicermat'
};

const LoginPage = () => {
  const { t } = useTranslation(['login']);
  const [isShowPinPassword, setIsShowPinPassword] = useState<boolean>(false);
  const { login, setErrorMessage, errorMessage } = useAuth();

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('login:validation.email')).required(t('login:validation.required')),
    password: Yup.string().required(t('login:validation.required'))
  });

  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';
  const { changeLanguage } = useI18n();

  const formik = useFormik({
    initialValues: {
      lang: currentLanguage as LanguageType,
      email: isDev ? mockLogin.email : '',
      password: isDev ? mockLogin.password : ''
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: async (submitValue: PostBodyLoginInterface) => {
      setErrorMessage('');
      login({
        email: submitValue.email,
        password: submitValue.password,
        lang: submitValue.lang
      });
    }
  });

  const handleChangeLanguage = (selected: LanguageType) => {
    formik.setFieldValue('lang', selected);
    changeLanguage(selected);

    setErrorMessage('');
  };

  return (
    <div className="flex flex-row min-h-screen">
      <div className="w-2/3 flex justify-center items-center bg-blue-darkBlue bg-[url(/images/login-background.png)] bg-bottom bg-no-repeat">
        <BaseText
          label={t('login:welcome', { appName: 'PinjamCermat' })}
          className="text-light-whiteSolid font-semibold text-[32px]"
        />
      </div>
      <div className="w-1/3 flex flex-col bg-light-whiteSolid p-12">
        <div className="flex flex-row items-center">
          <div className="flex flex-1">
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={
                'https://s3.ap-southeast-1.amazonaws.com/assets.frontend/new-toolkit/pinjam-cermat-sme-web/logo-pinjamCermat.svg'
              }
              className="w-[154px] h-[40px]"
            />
          </div>
          <BaseMenuDropdown
            data={[
              {
                label: 'ID',
                onSelectItem: () => {
                  handleChangeLanguage('id');
                },
                labelStyles: 'leading-[14px] font-normal text-left'
              },
              {
                label: 'EN',
                onSelectItem: () => {
                  handleChangeLanguage('en');
                },
                labelStyles: 'leading-[14px] font-normal text-left'
              }
            ]}
            labelStyles="text-blue-maastrichtBlue leading-[14px] font-normal"
            iconStyles="fill-blue-yaleBlue w-[12px] h-[12px]"
            containerStyles="w-[56px] h-[30px]"
            itemsContainerStyles="min-w-0 w-[55px]"
            activeBgColor={ColorLight.aliceBlue}
            inactiveBgColor={ColorLight.aliceBlue}
            activeLabelColor={ColorBlue.yaleBlue}
            inactiveLabelColor={ColorBlue.yaleBlue}
            inactiveIconColor={ColorBlue.yaleBlue}
            activeIconColor={ColorBlue.maastrichtBlue}
            label={currentLanguage.toUpperCase()}
            position="bottom-end"
          />
        </div>

        <div className="mt-12" />

        <div className="flex flex-col">
          <BaseText
            label={t('login:login')}
            className="text-dark-blackCoral text-lg font-semibold leading-[12px]"
          />
          <div className="mt-5" />
          <div className="flex flex-col w-full form-login-email">
            <BaseTextInput
              type="text"
              id="email"
              name="email"
              htmlFor="email"
              value={formik.values.email}
              label={t('login:email')}
              placeholder={t('login:enterEmail')}
              labelStyles="text-dark-gumbo text-[10px] font-normal -mt-1.5"
              onBlur={(e) => {
                formik.setFieldValue('email', e.target.value);
              }}
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                formik.setFieldError('email', undefined);
                formik.setFieldValue('email', e.target.value);
              }}
              isError={
                formik.touched.email &&
                // TODO: Refactor this
                (!!formik.errors.email ||
                  errorMessage === 'Account is not registered.' ||
                  errorMessage === 'The account not register' ||
                  errorMessage === 'Akun belum terdaftar' ||
                  errorMessage === 'Akun belum terdaftar.')
              }
              errorMessage={
                errorMessage === 'Account is not registered.' ||
                errorMessage === 'The account not register' ||
                errorMessage === 'Akun belum terdaftar' ||
                errorMessage === 'Akun belum terdaftar.'
                  ? t('login:validation.accountNotRegistered')
                  : formik.errors.email
              }
            />
          </div>

          <div className="mt-5" />
          <div className="flex flex-col w-full form-login-password">
            <BaseTextInput
              type={isShowPinPassword ? 'text' : 'password'}
              id="password"
              name="password"
              htmlFor="password"
              value={formik.values.password}
              onBlur={(e) => {
                formik.setFieldValue('password', e.target.value);
              }}
              label={t('login:password')}
              placeholder={t('login:enterPassword')}
              labelStyles="text-dark-gumbo text-[10px] font-normal -mt-1.5"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                formik.setFieldError('password', undefined);
                formik.setFieldValue('password', e.target.value);
              }}
              isError={
                formik.touched.password &&
                (!!formik.errors.password ||
                  errorMessage === 'Email or password is incorrect.' ||
                  errorMessage === 'Email or password is wrong' ||
                  errorMessage === 'Email atau kata sandi salah' ||
                  errorMessage === 'Email atau kata sandi salah.')
              }
              errorMessage={
                errorMessage === 'Email or password is incorrect.' ||
                errorMessage === 'Email or password is wrong' ||
                errorMessage === 'Email atau kata sandi salah' ||
                errorMessage === 'Email atau kata sandi salah.'
                  ? t('login:validationError')
                  : formik.errors.password
              }
              rightIcon={isShowPinPassword ? 'eyeon' : 'eyeoff'}
              onClickRightIcon={() => setIsShowPinPassword(!isShowPinPassword)}
            />
          </div>

          <div className="mt-6" />
          <BaseButton
            label={t('login:login')}
            className="bg-blue-yaleBlue border-blue-yaleBlue active:bg-blue-maastrichtBlue active:border-blue-maastrichtBlue w-full"
            onClick={() => formik.handleSubmit()}
          />

          <div className="mt-4" />

          <div className="w-full flex justify-between items-center h-[fit-content]">
            <Image
              src="/images/secure-ssl-image.svg"
              alt="secure-ssl-images"
              width={74}
              height={33}
            />

            <BaseText
              label={t('login:forgotPassword')}
              className="text-blue-maastrichtBlue text-[12px] font-normal leading-[14px]"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
